@layer components {
	.anim-bg-hover {
		@apply transition-colors
		ease-in
		duration-500

		hover:bg-primary-200
		hover:ease-out
		hover:duration-700

		active:bg-primary-100;
	}

	.custom-focus {
		@apply focus-visible:outline
		focus-visible:outline-2
		focus-visible:outline-focus-base
		focus-visible:outline-offset-1;
	}

	.focus-visible {
		@apply focus-visible:outline
		focus-visible:outline-focus-base
		focus-visible:outline-offset-0
		focus-visible:outline-2
		focus-visible:rounded-[3px];
	}
}
.plain-text {
	p {
		margin-top: 0.5rem;
	}
}

.format-text {
	h1 {
		@apply text-3xl
		mb-6;
	}
	h2 {
		@apply text-2xl
		mb-4;
	}
	h3 {
		@apply text-xl
		mb-4;
	}
	a {
		@apply underline
		text-primary-600

		hover:no-underline;
	}
	p {
		@apply mb-4;
	}
	ul,
	ol {
		@apply pl-8 mb-4;
		li {
			@apply mb-2;
		}
	}
	ol {
		li {
			@apply list-decimal;
		}
	}
}
