@layer components {
	.button-group .element {
		@apply w-full

		md:w-auto;
	}

	.button-full {
		.element {
			@apply w-full;
		}
	}
}
