@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
	--gov-container-width: 1238px;
	--gov-layout-content-width: 872px;
	--gov-form-checkbox-core-m-gap: 12px;
}

/**
	LOAD DESIGN SYSTEM CE
 */
$gov-font-path: '/assets/fonts';
$version: '0.0.1';
@import './assets/fonts/roboto';
@import '@gov-design-system-ce/styles/lib/critical.css';
@import '@gov-design-system-ce/styles/lib/styles.css';

@import 'styles/custom/custom';

@import 'styles/data-boxes/ds';

/**
	COMPONENTS STYLES
 */
@import 'styles/form/autocomplete';
@import 'styles/form/button';
@import 'styles/form/control';
@import 'styles/modal/modal';
@import 'styles/navigations/main-navigation';
@import 'styles/skeleton/skeleton';
@import 'styles/tables/data-table';
@import 'styles/tile/tile';
@import 'styles/wizzard/wizzard';

/**
	EXCEPTIONS IN STYLES
 */

.body-fixed,
.body-fixed #root > header {
	padding-right: 17px;
}
a > * {
	color: inherit;
}

.nav-primary--link {
	&:visited,
	&:visited > * {
		color: var(--gov-color-primary-600);
	}
}
h1 strong {
	font-weight: 700;
}

.gov-list--plain {
	li {
		margin-bottom: 0;
	}
}

@layer base {
	main a,
	main .gov-link {
		@apply underline
		text-primary-600

		hover:no-underline;
	}
}
