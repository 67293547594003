@layer components {
	.autocomplete {
		@apply hidden

			absolute
			top-12
			left-0
			z-10

			bg-neutral-white
			shadow-s
			rounded

			md:left-[88px];

		li {
			@apply mb-0;
		}

		button, a {
			@apply w-full
			h-10
			pr-4

			flex
			items-center

			text-left

			hover:bg-primary-200;
		}

		span {
			@apply inline-block;
		}

		button i, a i {
			@apply not-italic
			text-secondary-700;
		}

		strong {
			@apply font-normal;
		}

		.gov-icon {
			@apply w-4
			mx-3;
		}

		.selected {
			strong {
				@apply font-bold;
			}
			button > span, a > span {
				@apply text-primary-600

				border-l-2
				border-primary-600;
			}
		}
	}
}
