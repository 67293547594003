$gov-font-path: '..' !default;
$gov-font-display: auto !default;
/* latin-ext */
@font-face {
  font-family: Roboto;
  font-weight: 100;
  font-style: italic;
  font-display: $gov-font-display;
  src: local('Roboto Thin Italic'), local('Roboto-ThinItalic'),
    url('#{$gov-font-path}/roboto-thin-italic-ext.woff2' + '?v=' + $version)
      format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: Roboto;
  font-weight: 100;
  font-style: italic;
  font-display: $gov-font-display;
  src: local('Roboto Thin Italic'), local('Roboto-ThinItalic'),
    url('#{$gov-font-path}/roboto-thin-italic.woff2' + '?v=' + $version)
      format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: Roboto;
  font-weight: 300;
  font-style: italic;
  font-display: $gov-font-display;
  src: local('Roboto Light Italic'), local('Roboto-LightItalic'),
    url('#{$gov-font-path}/roboto-light-italic-ext.woff2' + '?v=' + $version)
      format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: Roboto;
  font-weight: 300;
  font-style: italic;
  font-display: $gov-font-display;
  src: local('Roboto Light Italic'), local('Roboto-LightItalic'),
    url('#{$gov-font-path}/roboto-light-italic.woff2' + '?v=' + $version)
      format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: Roboto;
  font-weight: 400;
  font-style: italic;
  font-display: $gov-font-display;
  src: local('Roboto Italic'), local('Roboto-Italic'),
    url('#{$gov-font-path}/roboto-italic-ext.woff2' + '?v=' + $version)
      format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: Roboto;
  font-weight: 400;
  font-style: italic;
  font-display: $gov-font-display;
  src: local('Roboto Italic'), local('Roboto-Italic'),
    url('#{$gov-font-path}/roboto-italic.woff2' + '?v=' + $version)
      format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: Roboto;
  font-weight: 500;
  font-style: italic;
  font-display: $gov-font-display;
  src: local('Roboto Medium Italic'), local('Roboto-MediumItalic'),
    url('#{$gov-font-path}/roboto-medium-italic-ext.woff2' + '?v=' + $version)
      format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: Roboto;
  font-weight: 500;
  font-style: italic;
  font-display: $gov-font-display;
  src: local('Roboto Medium Italic'), local('Roboto-MediumItalic'),
    url('#{$gov-font-path}/roboto-medium-italic.woff2' + '?v=' + $version)
      format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: Roboto;
  font-weight: 700;
  font-style: italic;
  font-display: $gov-font-display;
  src: local('Roboto Bold Italic'), local('Roboto-BoldItalic'),
    url('#{$gov-font-path}/roboto-bold-italic-ext.woff2' + '?v=' + $version)
      format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: Roboto;
  font-weight: 700;
  font-style: italic;
  font-display: $gov-font-display;
  src: local('Roboto Bold Italic'), local('Roboto-BoldItalic'),
    url('#{$gov-font-path}/roboto-bold-italic.woff2' + '?v=' + $version)
      format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: Roboto;
  font-weight: 100;
  font-style: normal;
  font-display: $gov-font-display;
  src: local('Roboto Thin'), local('Roboto-Thin'),
    url('#{$gov-font-path}/roboto-thin-ext.woff2' + '?v=' + $version)
      format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: Roboto;
  font-weight: 100;
  font-style: normal;
  font-display: $gov-font-display;
  src: local('Roboto Thin'), local('Roboto-Thin'),
    url('#{$gov-font-path}/roboto-thin.woff2' + '?v=' + $version)
      format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: Roboto;
  font-weight: 300;
  font-style: normal;
  font-display: $gov-font-display;
  src: local('Roboto Light'), local('Roboto-Light'),
    url('#{$gov-font-path}/roboto-light-ext.woff2' + '?v=' + $version)
      format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: Roboto;
  font-weight: 300;
  font-style: normal;
  font-display: $gov-font-display;
  src: local('Roboto Light'), local('Roboto-Light'),
    url('#{$gov-font-path}/roboto-light.woff2' + '?v=' + $version)
      format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: Roboto;
  font-weight: 400;
  font-style: normal;
  font-display: $gov-font-display;
  src: local('Roboto'), local('Roboto-Regular'),
    url('#{$gov-font-path}/roboto-regular-ext.woff2' + '?v=' + $version)
      format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: Roboto;
  font-weight: 400;
  font-style: normal;
  font-display: $gov-font-display;
  src: local('Roboto'), local('Roboto-Regular'),
    url('#{$gov-font-path}/roboto-regular.woff2' + '?v=' + $version)
      format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: Roboto;
  font-weight: 500;
  font-style: normal;
  font-display: $gov-font-display;
  src: local('Roboto Medium'), local('Roboto-Medium'),
    url('#{$gov-font-path}/roboto-medium-ext.woff2' + '?v=' + $version)
      format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: Roboto;
  font-weight: 500;
  font-style: normal;
  font-display: $gov-font-display;
  src: local('Roboto Medium'), local('Roboto-Medium'),
    url('#{$gov-font-path}/roboto-medium.woff2' + '?v=' + $version)
      format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: Roboto;
  font-weight: 700;
  font-style: normal;
  font-display: $gov-font-display;
  src: local('Roboto Bold'), local('Roboto-Bold'),
    url('#{$gov-font-path}/roboto-bold-ext.woff2' + '?v=' + $version)
      format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: Roboto;
  font-weight: 700;
  font-style: normal;
  font-display: $gov-font-display;
  src: local('Roboto Bold'), local('Roboto-Bold'),
    url('#{$gov-font-path}/roboto-bold.woff2' + '?v=' + $version)
      format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}
