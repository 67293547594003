@layer components {
	.is-visible {
		@apply flex;
	}

	.is-hidden {
		@apply hidden;
	}

	.ds-active {
		@apply border-2
		border-primary-600;
		@apply bg-primary-200 #{!important};
		@apply text-primary-700 #{!important};
	}

	.ds-no-underline .element {
		@apply no-underline #{!important};
	}

	.ds-checkbox-item input {
		@apply absolute
		top-0
		left-0
		z-10

		w-full
		h-full

		cursor-pointer;
	}

	.ds-new-message .gov-form-control__holder {
		border: inherit;
		@apply relative
		flex
		flex-col
		gap-2
		pb-2

		border-b
		border-secondary-400

		md:flex-row
		md:items-center
		md:min-h-[48px];
		.gov-form-label {
			@apply w-auto;
		}
		.gov-form-label__label {
			@apply relative
			top-0.5
			m-0
			w-20
			grow-0
			shrink-0;
		}
		.element input,
		.gov-form-control__holder {
			@apply border-0;
		}
		.gov-form-autocomplete__list {
			@apply shadow-s;
		}
	}
	.ds-new-message-dropdown .element {
		@apply text-left
		border-0;
		@apply hover:bg-primary-200 #{!important};
		> span {
			@apply inline;
			.gov-icon {
				@apply relative
				top-1
				mr-2;
			}
		}
		strong {
			@apply font-normal;
			+ span {
				@apply font-normal
				text-secondary-700;
			}
		}
	}

	.ds-subject .gov-form-control[invalid='true'] {
		.gov-form-control__holder {
			@apply mb-[25px];
			.gov-form-control__bottom {
				@apply absolute
				-bottom-[42px];
			}
		}
	}
}
