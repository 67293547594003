@layer components {
	.nav-primary--link {
		@apply flex items-center
		py-0 px-2

		text-xl
		text-primary-600

		hover:text-primary-600

		visited:text-primary-600

		after:content-['']
		after:absolute after:-bottom-1.5 after:left-0 after:right-0
		after:h-0.5
		after:rounded-sm
		after:animate-scale-x-out
		after:duration-500

		after:hover:bg-primary-600
		after:hover:animate-scale-x-in;
	}
	.nav-primary--text {
		@apply invisible
		absolute

		ml-3

		text-primary-600

		sm:visible
		sm:relative;
	}
	.nav-primary--link.is-active {
		@apply after:content-['']
		after:absolute after:-bottom-1.5 after:left-0 after:right-0
		after:h-0.5
		after:rounded-sm
		after:bg-primary-600
		after:animate-none

		after:hover:animate-none;
	}
	.nav-primary--badge {
		@apply absolute -top-2 left-6 z-10
		flex justify-center items-center

		w-5 h-5

		rounded-xl
		bg-warning-400

		text-xs

		sm:-top-1
		sm:w-6 sm:h-6
		sm:text-xs;
	}
}
